import axios from "axios";
import { useEffect, useState } from "react";
import util from "util";
import "./App.css";
import "./assets/scss/main.scss";
import ScoreBoard from "./pages/ScoreBoard";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { io } from "socket.io-client";
import store from "./redux/store";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import useWebSocket from 'react-use-websocket';

var sample_match_key;

function App({ props }) {
  const socket = useSelector((state) => state.SocketModel.socket);
  const [sockets, setSockets] = useState();
  const [accessToken, setAccessToken] = useState();
  const { dispatch } = store;

  sample_match_key = props.match.params.match_id;
  console.log('props.match.params', props.match.params)

  const encryptionPassword = 'it_is_satradar_env_password';
  const iv = 'it_is_satradar_env_iv'; // This should be a random and unique value, not hard-coded in the code

  // useEffect(() => {
  //   setTimeout(() => {
  //     connectSocket();
  //   }, 100);
  // }, []);

  useWebSocket(`wss://myzosh.com:3366/?match_id=${props.match.params.match_id}&EIO=4&transport=polling&t=OjBwkKN`, {
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    onMessage: (message) => {
      const messageData = JSON.parse(message.data);
      if (messageData.action === 'match_joined') {
        console.log('match_joined', messageData.data);
      } else if (messageData.action === 'received_match_data') {
        console.log('received_match_data', messageData.data.data);
        dispatch.MatchModel.getMatchDetail({ data: messageData.data.data, from: 'socket' });
      } else if (messageData.action === 'error_subscription') {
        console.log('message.data.data', messageData.data);
      }
    },
    share: true,
    filter: () => false
  });

  useEffect(() => {
    async function socketMethods() {
      if (socket.connected) {
        // Add a connect listener
        socket?.on('connect', function (socket) {
          console.log('Connected!')
        })

        const token = await getAccessToken();
        setAccessToken(token.token);

        // server emits 'on_match_joined'
        socket?.on('match_joined', function (data) {
          console.log('Cricket match joined!', data)
        })
        // the subscribed match content are emitted with 'on_match_update' event
        socket?.on('received_match_data', function (res) {
          console.log('data received', res)
          dispatch.MatchModel.getMatchDetail({ data: res?.data, from: 'socket' });
        })
        // emits 'on_error' on,
        //match not subscribed
        socket?.on('error_subscription', function (data) {
          console.log('not_subscribed', data)
        })
      }
    }
    setTimeout(() => {
      socketMethods();
    }, [500])
  }, [socket])

  // Decrypt data
  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionPassword, { iv });
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (error) {
      // Handle decryption error
      console.error('Decryption error:', error.message);
      return null;
    }
  };

  // Decrypt color code
  const decryptColor = (encrypted) => {
    // console.log('encrypted', encrypted)
    if(!encrypted){
      return '';
    }
    else{
      const decrypted = atob(encrypted);
      // console.log('decrypted', decrypted);
      return decrypted;
    }
  }

  // configuration
  let cricketHost = decryptData("U2FsdGVkX18lbfzTpaqhQeejd7PunLBUSJDeXMrFf+kXao6RwfM6QjMhyA0T33WE");
  let cricketProjectKey = decryptData("U2FsdGVkX19f6u/j+SqKuPiMWb5Bspa4BUlmniArhmYoanQlCjn4guySiDbH5zAh");
  let cricketApiKey = decryptData("U2FsdGVkX1+8dbd+ot2XIe+V3bKEMs0LURlrIL15LmbWe5nUrK7IFDWkpB2aYsybtvSGynLqWLHVigovR+fbZQ==");

  function getAccessToken() {
    return new Promise(resolve => {
      var options = {
        method: 'POST',
        url: cricketHost + '/v5/core/' + cricketProjectKey + '/auth/',
        body: JSON.stringify({
          api_key: `${cricketApiKey}`
        })
      }

      axios.post(options.url, options.body, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          if (response.data.http_status_code == 200) {
            resolve({
              token: response.data.data.token
            })
          }
        })
        .catch(error => {
          console.log("An error occurred while getting access token: ", error);
          resolve(false);
        });
    });
  }

  const subscribeSocket = () => {
    var project_key = cricketProjectKey
    var token = accessToken
    var match_key = props.match.params.match_id
    var options = {
      method: 'POST',
      url: `https://api.sports.roanuz.com/v5/cricket/${project_key}/match/${match_key}/subscribe/`,
      headers: {
        'rs-token': `${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        method: "web_socket"
      })
    }
    axios.post(options.url, options.body, {
      headers: {
        'rs-token': `${token}`,
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      console.log('res', res);
    })
  }

  // function connectSocket() {

  //   var sock = io.connect('ws://localhost:5555', { query: { match_id: props.match.params.match_id } })
  //   dispatch.SocketModel.setSocketData(sock);
  // }

  return <ScoreBoard matchId={props.match.params.match_id} color={decryptColor(props.match.params.color)} />;
}

export default App;

import { getAccessToken } from "../../api/accessToken";
import axiosClient from "../../api/axiosClient";

const MatchModel = {
    state: { matchData: {}, dataFrom: '' },
    reducers: {
        setMatchDetail(state, payload) {
            return { matchData: { ...payload.data }, dataFrom: payload.from };
        },
    },
    effects: () => ({
        async getMatchDetail(data) {
            if (data.from === 'api') {
                const token = await getAccessToken();
                let response = await axiosClient.post(`https://myzosh.com/scoreboard/match/get_live_score/${data.matchId}`, { access_token: token });
                this.setMatchDetail({ data: response.data.data, from: data.from })
            }
            else {
                this.setMatchDetail({ data: data.data, from: data.from })
            }
        },
    }),
};

export default MatchModel;